/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 the_moon.glb
Author: SebastianSosnowski (https://sketchfab.com/SebastianSosnowski)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/the-moon-9916fcec59f04b07b3e8d7f077dc3ded
Title: The Moon
*/

import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function MoonModel(props) {
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/Moon.glb')
  const mesh =useRef(null);
  useFrame(() => {
    mesh.current.rotation.y = mesh.current.rotation.y += 0.008
  });
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh 
          ref={mesh} 
          geometry={nodes.defaultMaterial.geometry} 
          material={materials.Material__50} 
          rotation={[Math.PI / 2, 0, 0]} 
        />
      </group>
    </group>
  )
}

useGLTF.preload(process.env.PUBLIC_URL + '/Moon.glb')
