/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 Star.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

export function StarModel(props) {
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/Star.glb')
  const mesh =useRef(null);
  useFrame(() => {
    mesh.current.rotation.y = mesh.current.rotation.x += 0.003
  });
  return (
    <group {...props} dispose={null}>
      <mesh ref={mesh} geometry={nodes.Circle.geometry} material={materials['Material.001']} rotation={[1.32, 0.82, 0.23]} scale={[0.67, 1, 1]} />
    </group>
  )
}

useGLTF.preload('/Star.glb')
